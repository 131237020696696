import { LoadConfig } from "../config.ts";
import { themeManager } from "../logic/theme.ts";
import { acceptInvite, logout, tryAuthenticate, userIsAuthed, userLogin } from "./auth.ts";
import { queueTask } from "./tasks.ts";

export const TaskDefs = {
    callback: {
        name: "Log you in",
        function: tryAuthenticate
    },
    acceptInvite: {
        name: "Register your account",
        function: acceptInvite
    },
    ensureUserAuth: {
        name: "Check account details",
        function: async () => {
            if (!await userIsAuthed()) 
                queueTask(["login"], "next");
        }
    },
    login: {
        name: "Redirect to login page",
        function: () => {
            queueTask([], "replace");
            return userLogin();
        }
    },



    clearAuthStorage: {
        name: "Log out of this browser",
        function: async () => {
            localStorage.clear();
            sessionStorage.clear();
        }
    },
    clearIndexedDB: {
        name: "Remove cached data",
        function: async () => {
            let dbs = await indexedDB.databases();
            await Promise.all(
                dbs.map((db) => indexedDB.deleteDatabase(db.name!))
            );
        }
    },

    logout: {
        name: "Log out other browsers",
        nonFatal: true,
        function: logout
    },


    loadConfig: {
        name: "Load app config",
        function: async () => 
            LoadConfig().then(() => themeManager.apply())       
    },

    initApp: {
        name: "Load app",
        function: async () => 
            import("../ui/app.tsx").then(m => m.InitApp())
        
    },
    reload: {
        name: "Restart app",
        function: async () => location.replace("/")
    },
    zohoCallback: {
        name: "Finish Zoho login",
        function: async () => 
            import("../logic/zoho/zohoAuth.ts").then(m => m.handleZohoCallback())
    },
    zohoLogout: {
        name: "Log out of Zoho",
        function: async () => 
            localStorage.removeItem("zohoToken")
    },
    zohoRedirect: {
        name: "Redirect to Zoho login",
        function: async () => 
            import("../logic/zoho/zohoAuth.ts").then(m => location.replace(m.getZohoRedirectUrl()))
    },
    close: {
        name: "Close window",
        function: async () => window.close()
    }

} satisfies TaskDefs;

let openerIsSameOrigin = false;
try {
    openerIsSameOrigin = window.opener.location.origin === window.location.origin;
} catch(e) {}

export const defaultTaskList =  ["ensureUserAuth", "loadConfig", "initApp"] as TaskName[];
const ClearTaskList = ["clearAuthStorage", "clearIndexedDB"] as TaskName[];
export const TaskLists = {
    "default": defaultTaskList,
    "invite": ["acceptInvite", "login"],
    "callback": ["callback",...(openerIsSameOrigin ? ["close"] : defaultTaskList)],
    "clear_local": ClearTaskList,
    "logout": ["logout", ...ClearTaskList],
    "zoho_callback": ["zohoCallback", 'close'],
    "zoho_redir": ["zohoLogout", "loadConfig","zohoRedirect"]
} as Record<string, TaskName[]>;

export type TaskName = keyof typeof TaskDefs;